// Required
@import "~/node_modules/bootstrap/scss/functions";

// Default variable overrides
$body-bg: #000;
$body-color: #111;

// Configuration
@import "~/node_modules/bootstrap/scss/variables";
@import "~/node_modules/bootstrap/scss/mixins";
@import "~/node_modules/bootstrap/scss/utilities";

// Layout & components
@import "~/node_modules/bootstrap/scss/root";
@import "~/node_modules/bootstrap/scss/reboot";
@import "~/node_modules/bootstrap/scss/type";
@import "~/node_modules/bootstrap/scss/images";
@import "~/node_modules/bootstrap/scss/containers";
@import "~/node_modules/bootstrap/scss/grid";
@import "~/node_modules/bootstrap/scss/tables";
@import "~/node_modules/bootstrap/scss/forms";
@import "~/node_modules/bootstrap/scss/buttons";
@import "~/node_modules/bootstrap/scss/transitions";
@import "~/node_modules/bootstrap/scss/dropdown";
@import "~/node_modules/bootstrap/scss/nav";
@import "~/node_modules/bootstrap/scss/navbar";
@import "~/node_modules/bootstrap/scss/card";
@import "~/node_modules/bootstrap/scss/accordion";
@import "~/node_modules/bootstrap/scss/breadcrumb";
@import "~/node_modules/bootstrap/scss/pagination";
@import "~/node_modules/bootstrap/scss/badge";
@import "~/node_modules/bootstrap/scss/alert";
@import "~/node_modules/bootstrap/scss/progress";
@import "~/node_modules/bootstrap/scss/list-group";
@import "~/node_modules/bootstrap/scss/close";
@import "~/node_modules/bootstrap/scss/toasts";
@import "~/node_modules/bootstrap/scss/modal";
@import "~/node_modules/bootstrap/scss/tooltip";
@import "~/node_modules/bootstrap/scss/popover";
@import "~/node_modules/bootstrap/scss/carousel";
@import "~/node_modules/bootstrap/scss/spinners";
@import "~/node_modules/bootstrap/scss/offcanvas";
@import "~/node_modules/bootstrap/scss/placeholders";

// Helpers
@import "~/node_modules/bootstrap/scss/helpers";

// Utilities
@import "~/node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

body {
  position: relative; // Required for scrollspy - See https://getbootstrap.com/docs/5.0/components/scrollspy
}

@include media-breakpoint-up(sm) {
  body { padding-top: 0px; }
}

img.cover {
  object-fit: cover;
  width: 100vw;
  height: auto;
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
}

/*
 * Base structure
 */

#contact a {
  color: white;
}

/*
 * Extra utilities
 */

.flex-equal > * {
  flex: 1;
}
@media (min-width: 768px) {
  .flex-md-equal > * {
    flex: 1;
  }
}
